import { twMerge } from 'tailwind-merge';

type Props = {
  width?: string | number;
  height?: string | number;
  className?: string;
};

const Skeleton = ({ width, height, className }: Props) => (
  <span
    className={twMerge('animate-pulse rounded bg-grayscale-16 inline-block', className as string)}
    style={{ height, width }}
  />
);

type ParaProps = {
  center?: boolean;
  className?: string;
};

export const SkeletonParagraph = ({ center, className }: ParaProps) => (
  <div className={`flex flex-col ${className ?? ''}`}>
    <Skeleton className={`h-4 mr-8 my-1 ${center ? 'ml-8' : ''}`} />
    <Skeleton className={`h-4 mr-4 my-1 ${center ? 'ml-4' : ''}`} />
    <Skeleton className={`h-4 mr-12 my-1 ${center ? 'ml-12' : ''}`} />
  </div>
);

export default Skeleton;
