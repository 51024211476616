import * as React from 'react';
import { classNames } from 'utils/src/classnames';
import { useButtonClassNames } from './styles';

type Variant = 'primary' | 'secondary' | 'ghost' | 'link' | 'float';
type Size = 'sm' | 'md' | 'lg';

export type ButtonProps = {
  variant?: Variant;
  children: React.ReactNode;
  size?: Size;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  /**
   * Render component as a certain React Element (for example anchor, link or any other
   * component or just HTML tag).
   *
   * @see https://styled-components.com/docs/api#as-polymorphic-prop
   */
  as?: React.ElementType;
  iconColor?: string;
};

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps;

const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    children,
    type = 'button',
    variant = 'primary',
    size = 'md',
    as = 'button',
    iconColor = 'currentColor',
    ...rest
  } = props;
  const styles = useButtonClassNames({ ...props, variant, size });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newChildren = React.Children.toArray(children).map((child: any, index, array) => {
    if (child.type?.displayName === 'BENEPASS_ICON') {
      const base = classNames([
        // Add button-left or button-right classname only if the length of the children
        // array is greater than 1 (sometimes we can display a button only with an icon)
        array.length > 1 ? `button-${index === 0 ? 'left' : 'right'}-icon` : '',
        child.props.className ?? '',
      ]);
      return React.cloneElement(child, {
        className: base,
      });
    }

    return child;
  });

  const role = React.useMemo(() => (as === 'div' ? 'button' : undefined), [as]);
  const Component = as;

  const buttonClasses = classNames([
    styles,
    '[&_svg]:text-[var(--icon-color)]',
    '[&_.button-left-icon]:!mr-2',
    '[&_.button-right-icon]:!ml-2',
    'disabled:bg-opacity-100',
  ]);

  return (
    <Component
      {...rest}
      ref={ref}
      className={buttonClasses}
      type={type}
      role={role}
      style={{ '--icon-color': iconColor } as React.CSSProperties}
    >
      {newChildren}
    </Component>
  );
});

Button.displayName = 'Button';

export default Button;
