import React, { useMemo, useCallback } from 'react';
import type { Moment } from 'moment';
import { fromDate, toLocalTimeNoZone } from 'utils/src/DateTime';
import { Calendar as CalendarIcon, Reply as ReplyIcon } from '@benepass/icons';
import { grayscale } from '@benepass/colors';

type Range = {
  from: Moment;
  to: Moment;
};

type PropsItem = React.PropsWithChildren<{
  range?: Range;
  onClick: (arg0: Range | null | undefined) => void;
  icon: 'calendar' | 'arrow';
}>;

const Item = ({ children, range, icon, onClick }: PropsItem): JSX.Element => {
  const handleClick = useCallback(() => {
    onClick(range);
  }, [range, onClick]);

  return (
    <li className="not-last:mb-4">
      <button type="button" onClick={handleClick} className="w-full text-left cursor-pointer">
        <div className="flex flex-row">
          <div className="mr-2">
            {icon === 'calendar' ? <CalendarIcon color={grayscale[64]} /> : <ReplyIcon color={grayscale[64]} />}
          </div>
          <div>
            <span className="text-xs mr-2 font-medium text-grayscale-80">{children}</span>
            {range && (
              <span className="text-xs text-grayscale-64">
                <br />
                {range?.from.format('MMM DD')} - {range?.to.format('MMM DD')}
              </span>
            )}
          </div>
        </div>
      </button>
    </li>
  );
};

type Props = {
  onSelect: (from?: Date, to?: Date) => void;
  maxDate?: Date;
  excludedShortcuts?: string[];
};

const DateRangePickerShortcuts = ({ onSelect, maxDate, excludedShortcuts }: Props) => {
  const handleClick = (range: Range | null | undefined) => {
    onSelect(toLocalTimeNoZone(range?.from), toLocalTimeNoZone(range?.to));
  };

  const last30Days = useMemo(() => {
    const to = fromDate();
    const from = fromDate().subtract(30, 'days');
    return { from, to };
  }, []);

  const thisMonth = useMemo(() => {
    const to = fromDate();
    const from = fromDate().startOf('month');
    return { from, to };
  }, []);

  const lastMonth = useMemo(() => {
    const to = fromDate().subtract(1, 'month').endOf('month');
    const from = fromDate().subtract(1, 'month').startOf('month');
    return { from, to };
  }, []);

  const last3Months = useMemo(() => {
    const to = fromDate().subtract(1, 'month').endOf('month');
    const from = fromDate().subtract(3, 'month').startOf('month');
    return { from, to };
  }, []);

  const last6Months = useMemo(() => {
    const to = fromDate().subtract(1, 'month').endOf('month');
    const from = fromDate().subtract(6, 'month').startOf('month');
    return { from, to };
  }, []);

  const thisYear = useMemo(() => {
    let to = fromDate();
    if (fromDate(maxDate) < to) {
      to = fromDate(maxDate);
    }
    const from = fromDate().startOf('year');
    return { from, to };
  }, [maxDate]);

  const lastYear = useMemo(() => {
    const to = fromDate().subtract(1, 'year').endOf('year');
    const from = fromDate().subtract(1, 'year').startOf('year');
    return { from, to };
  }, []);

  if (excludedShortcuts?.includes('all')) return null;

  return (
    <ul className="w-40">
      {excludedShortcuts?.includes('last_30days') ? (
        <Item onClick={handleClick} range={last30Days} icon="calendar">
          Last 30 days
        </Item>
      ) : null}
      {excludedShortcuts?.includes('this_month') ? (
        <Item onClick={handleClick} range={thisMonth} icon="calendar">
          This month
        </Item>
      ) : null}
      <Item onClick={handleClick} range={lastMonth} icon="calendar">
        Last month
      </Item>
      <Item onClick={handleClick} range={last3Months} icon="arrow">
        Last 3 months
      </Item>
      <Item onClick={handleClick} range={last6Months} icon="arrow">
        Last 6 months
      </Item>
      <Item onClick={handleClick} range={thisYear} icon="arrow">
        This year
      </Item>
      <Item onClick={handleClick} range={lastYear} icon="arrow">
        Last year
      </Item>
    </ul>
  );
};

export default DateRangePickerShortcuts;
