import React from 'react';
import styles from './DateRangePickerContainer.module.css';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const DateRangePickerContainer = ({ children, className }: Props): JSX.Element => (
  <div className={`${styles.container} ${className || ''}`}>{children}</div>
);

export default DateRangePickerContainer;
