import React from 'react';
import Logo from './components/logo';

const LayoutLoginContainer = ({ children }: React.PropsWithChildren) => (
  <main className="bg-grayscale-8 flex items-center justify-center h-screen relative">
    <div className="absolute top-[46px] left-1/2 -translate-x-1/2 sm:block hidden">
      <Logo variant="full" />
    </div>
    {children}
  </main>
);

export default LayoutLoginContainer;
