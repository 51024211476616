import React, { useMemo } from 'react';
import { ReactComponent as ErrorIcon } from './icons/error.svg';

export const MessageTypes = {
  ERROR: 'ERROR',
};

type Props = React.PropsWithChildren<{
  className?: string;
  type?: string;
}>;

const Message = ({ className, type = MessageTypes.ERROR, children }: Props): JSX.Element => {
  const baseClasses = 'border rounded-lg border-current flex pl-4 pr-6 py-4 items-start';
  const errorClasses = 'text-[var(--color-red-100)] bg-[var(--color-red-10)]';

  const containerClasses = useMemo(() => {
    const classes = [baseClasses];
    if (type === MessageTypes.ERROR) {
      classes.push(errorClasses);
    }
    if (className) {
      classes.push(className);
    }
    return classes.join(' ');
  }, [type, className]);

  const icon = useMemo(() => {
    if (type === MessageTypes.ERROR) {
      return <ErrorIcon className="mr-2 mt-1 flex-shrink-0" />;
    }
    return null;
  }, [type]);

  return (
    <div className={containerClasses}>
      {icon}
      <p className="p-0 m-0 flex-1 text-sm font-medium leading-6 text-justify">{children}</p>
    </div>
  );
};

Message.MessageTypes = MessageTypes;

export default Message;
