import React, { useCallback } from 'react';
import { LayoutUtils } from 'utils';
import clsx from 'clsx';
import type { AsElement } from '../text';
import * as Tooltip from '../tooltip';
import Text from '../text';
import styles from './Card.module.css';

const { AlignItems } = LayoutUtils;
export { AlignItems };

type Props = React.PropsWithChildren<{
  as?: AsElement;
  title?: string;
  alignItems?: (typeof AlignItems)[keyof typeof AlignItems];
  columns?: string;
  margin?: string;
  border?: boolean;
  className?: string;
  tooltip?: string;
}>;

function CardHeader(props: Props): JSX.Element {
  const {
    children,
    margin = 'normal',
    className,
    title,
    border = true,
    as = 'h1',
    columns = '1fr 1fr',
    alignItems = AlignItems.CENTER,
    tooltip,
  } = props;
  const style = {
    gridTemplateColumns: columns,
    margin,
    alignItems,
  };
  const TooltipWrapper = useCallback(
    ({ children: tooltipChildren }: { children?: React.ReactNode }) => {
      if (tooltip) {
        const [firstChild] = React.Children.toArray(tooltipChildren).filter((child) =>
          React.isValidElement(child)
        ) as React.ReactElement[];
        if (!firstChild) return tooltipChildren as JSX.Element;
        return (
          <Tooltip.Root>
            <Tooltip.Trigger>
              {React.cloneElement(firstChild, {
                ...(firstChild.props ?? {}),
                className: 'cursor-help',
              })}
            </Tooltip.Trigger>
            <Tooltip.Content align="start" side="top">
              <Tooltip.Arrow offset={5} width={11} height={5} />

              <div className="bg-grayscale-100 p-4 rounded max-w-xs">
                <Text className="text-white">{tooltip}</Text>
              </div>
            </Tooltip.Content>
          </Tooltip.Root>
        );
      }

      return tooltipChildren as JSX.Element;
    },
    [tooltip]
  );
  return (
    <header
      className={clsx('gap-2', styles.header, className, styles[margin as keyof typeof styles], {
        [styles.border as string]: border,
      })}
      style={style}
    >
      <TooltipWrapper>
        {title ? (
          <Text as={as} type="header-1">
            {title}
          </Text>
        ) : null}
      </TooltipWrapper>

      {children}
    </header>
  );
}

export default CardHeader;
