import React, { Suspense } from 'react';
import * as PrimitiveTabs from '@radix-ui/react-tabs';
import customTwMerge from '../../utils/twMerge';
import Loading from '../../Loading';
import {
  Carousel,
  CarouselSlideList,
  CarouselSlide,
  CarouselNext,
  CarouselPrevious,
  CarouselArrowWrapper,
} from '../carousel';
import { NetworkErrorBoundary } from 'rest-hooks';
import { AngleLeft, AngleRight } from '@benepass/icons';

type TabsProps = PrimitiveTabs.TabsProps &
  React.PropsWithChildren<{
    value?: string;
    defaultValue?: string;
    onValueChange?: (value: string) => void;
    activationMode?: 'automatic' | 'manual';
  }>;

export const Root = ({ children, ...props }: TabsProps): JSX.Element => (
  <PrimitiveTabs.Root {...props}>{children}</PrimitiveTabs.Root>
);

type CustomListProps = {
  bgColor?: string;
};

type ListProps = React.PropsWithChildren<PrimitiveTabs.TabsListProps & React.RefAttributes<HTMLDivElement>> &
  CustomListProps;

export const List = ({ children, bgColor = 'grayscale-2', ...props }: ListProps): JSX.Element => {
  return (
    <Carousel className="relative">
      <CarouselSlideList className="x mandatory-scroll-snapping">
        <PrimitiveTabs.List {...props} className={customTwMerge('flex gap-x-12', props.className)}>
          {children}
        </PrimitiveTabs.List>
      </CarouselSlideList>

      <CarouselArrowWrapper direction="previous">
        <div
          className={`bg-${bgColor} hidden absolute md:flex h-full bg-grayscale-2 pl-2 pr-2 border-r place-content-center`}
          style={{
            top: '0px',
            left: '0px',
          }}
        >
          <CarouselPrevious
            aria-label="Show next demo"
            tabIndex={-1}
            className="rounded-full my-auto bg-white border border-grayscale-16 p-1"
          >
            <AngleLeft size={20} />
          </CarouselPrevious>
        </div>
      </CarouselArrowWrapper>

      <CarouselArrowWrapper direction="next">
        <div
          className={`bg-${bgColor} hidden absolute md:flex h-full pl-2 pr-2 border-l place-content-center`}
          style={{
            top: '0px',
            right: '0px',
          }}
        >
          <CarouselNext
            aria-label="Show previous demo"
            tabIndex={-1}
            className="rounded-full my-auto bg-white border border-grayscale-16 p-1"
          >
            <AngleRight size={20} />
          </CarouselNext>
        </div>
      </CarouselArrowWrapper>
    </Carousel>
  );
};

type TriggerProps = React.PropsWithChildren<{
  value: string;
  disabled?: boolean;
}>;

export const Trigger = ({ children, ...props }: TriggerProps): JSX.Element => (
  <CarouselSlide className="snap-initial">
    <PrimitiveTabs.Trigger
      {...props}
      className={customTwMerge(
        'py-2 mx-4 w-max first:ml-0 last:mr-0 font-normal text-base cursor-pointer select-none',
        'text-grayscale-64 hover:border-b-2 hover:border-purple-100 focus:border-b-2 focus:border-purple-100',
        'focus:outline-none',
        'data-[state=active]:font-medium data-[state=active]:border-b-2 data-[state=active]:border-pink-100',
        'data-[state=active]:hover:border-pink-100 data-[state=active]:text-indigo-100'
      )}
    >
      {children}
    </PrimitiveTabs.Trigger>
  </CarouselSlide>
);

type ContentProps = React.PropsWithChildren<{
  className?: string;
  value: string;
}>;

export const Content = ({ children, className, ...props }: ContentProps): JSX.Element => (
  <PrimitiveTabs.Content
    {...props}
    className={customTwMerge('py-4 focus:outline-none data-[state=inactive]:hidden', className)}
  >
    {children}
  </PrimitiveTabs.Content>
);

export const ContentLoaded = ({
  children,
  value,
  loader,
  ...props
}: ContentProps & { loader?: React.ReactNode }): JSX.Element => (
  <Content value={value} {...props}>
    <Suspense fallback={loader || <Loading />}>
      <NetworkErrorBoundary>{children}</NetworkErrorBoundary>
    </Suspense>
  </Content>
);

export default Root;
