import React from 'react';
import Text from './components/text';
import Logo from './components/logo';
import Button from './components/button';
import { ArrowLeft } from '@benepass/icons';

type Props = React.PropsWithChildren<{
  title?: string;
  onBack?: (event: React.MouseEvent<HTMLButtonElement>) => unknown;
}>;

const LayoutLoginContentBox = ({ children, title, onBack }: Props): JSX.Element => (
  <div className="rounded-md px-4 py-6 md:px-24 md:py-20 bg-grayscale-0 flex flex-col gap-8 h-screen md:h-auto w-full md:w-auto relative">
    {onBack && (
      <Button variant="ghost" onClick={onBack} className="absolute top-4 left-4 w-max">
        <ArrowLeft size={24} />
      </Button>
    )}

    <div className="sm:hidden block h-20 w-28 object-cover object-left self-center">
      <Logo />
    </div>
    {title ? (
      <Text type="display-3" className="text-center">
        {title}
      </Text>
    ) : null}

    <div className="flex flex-col gap-4">{children}</div>
  </div>
);

export default LayoutLoginContentBox;
