import React from 'react';
import * as PrimitiveAccordion from '@radix-ui/react-accordion';
import twMerge from '../../utils/twMerge';
import { Item } from './item';

export { default as Loader } from './loader';
export { Header } from './header';
export { Content } from './content';
export { Item };

type Props = (PrimitiveAccordion.AccordionSingleProps | PrimitiveAccordion.AccordionMultipleProps) & {
  children: React.ReactNode;
  type: 'single' | 'multiple';
  value?: string | string[];
  defaultValue?: string | string[];
  onValueChange?: (value: string | string[]) => void;
  disabled?: boolean;
  collapsible?: boolean;
  className?: string;
};

export const Root = ({
  children,
  type,
  value,
  defaultValue,
  onValueChange,
  disabled = false,
  collapsible = false,
  className,
  ...props
}: Props): JSX.Element => (
  // @ts-expect-error Ignore me
  <PrimitiveAccordion.Root
    {...props}
    disabled={disabled}
    value={value}
    defaultValue={defaultValue}
    onValueChange={onValueChange}
    type={type}
    collapsible={collapsible || undefined}
    className={twMerge('flex flex-col gap-3', className)}
  >
    {children}
  </PrimitiveAccordion.Root>
);
