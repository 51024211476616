import React from 'react';

type Props = React.PropsWithChildren<{
  shortcuts?: JSX.Element;
  onlyNavbar?: boolean;
}>;

const DateRangePickerOverlay = ({ children, shortcuts, onlyNavbar }: Props): JSX.Element => {
  return (
    <div className="bg-white rounded-lg shadow-[0px_1px_2px_rgba(0,0,0,0.06),0px_1px_3px_rgba(0,0,0,0.1)]">
      <div className="rounded-lg relative flex shadow-none">
        {shortcuts ? <div className="p-5 pr-3 bg-grayscale-2 rounded-l-lg">{shortcuts}</div> : null}
        <div className={`p-5 pl-3 [&_.DayPicker]:min-h-[350px] ${onlyNavbar ? 'pb-1' : ''}`}>{children}</div>
      </div>
    </div>
  );
};

export default DateRangePickerOverlay;
