import * as PrimitiveAccordion from '@radix-ui/react-accordion';
import twMerge from '../../utils/twMerge';
import React from 'react';
import { classNames } from 'utils/src/classnames';
import { AngleDown } from '@benepass/icons';
import { getChildName } from 'utils/src/react-utils';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Icon = ({ children, ...props }: React.PropsWithChildren<React.HTMLProps<'div'>>) => {
  const moreThanOne = React.Children.toArray(children).length > 1;

  return (
    <>
      {React.Children.map(children, (child, index) => {
        if (child && React.isValidElement(child)) {
          return React.cloneElement(child, {
            key: child.key,
            ...props,
            className: twMerge(
              child.props?.className || '',
              'chevron',
              // eslint-disable-next-line no-nested-ternary
              moreThanOne ? (index > 0 ? 'open' : 'close') : ''
            ),
          } as Partial<unknown>);
        }

        return null;
      })}
    </>
  );
};

Icon.displayName = 'BENEPASS_ACCORDION_HEADER_ICON';

export const Header = ({ children, className, ...props }: Props & PrimitiveAccordion.AccordionHeaderProps) => {
  const classes = classNames(['px-8 py-0 gap-6', className ?? '']);

  const IconElements = React.Children.toArray(children).filter((child) => getChildName(child) === Icon.displayName);
  const RestElements = React.Children.toArray(children).filter((child) => getChildName(child) !== Icon.displayName);

  return (
    <PrimitiveAccordion.Header {...props} className={twMerge(classes)}>
      <PrimitiveAccordion.Trigger className="w-full flex items-center [&_.chevron]:transition-transform [&_.chevron]:duration-300 [&[data-state=open]_.chevron]:rotate-180 [&[data-state=open]_.chevron.close]:hidden [&[data-state=closed]_.chevron.open]:hidden">
        <div className="flex flex-1 text-left">{RestElements}</div>
        {IconElements.length ? (
          IconElements
        ) : (
          <AngleDown className="chevron self-center text-grayscale-100" aria-hidden size="24px" />
        )}
      </PrimitiveAccordion.Trigger>
    </PrimitiveAccordion.Header>
  );
};

Header.displayName = 'BENEPASS_ACCORDION_HEADER_ROOT';
Header.Icon = Icon;

export default Header;
