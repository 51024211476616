import React from 'react';
import * as Primitive from '@radix-ui/react-dropdown-menu';
import { DropdownMenuTriggerProps } from '@radix-ui/react-dropdown-menu';
import twMerge from '../../utils/twMerge';
import Text from '../text';

type PrimitiveProps = DropdownMenuTriggerProps;

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
} & PrimitiveProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Component = React.forwardRef<any, any>(({ children, ...props }, ref) => {
  const filteredElements = ['DROPDOWN_TRIGGER_TITLE', 'DROPDOWN_TRIGGER_SUBTITLE'];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const titleAndSubtitleElements = React.Children.toArray(children).filter((child: any) =>
    filteredElements.includes(child.type?.displayName)
  );
  const otherElements = React.Children.toArray(children).filter(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (child: any) => !filteredElements.includes(child.type?.displayName)
  );

  return (
    <>
      {React.Children.map(otherElements, (child) =>
        React.isValidElement(child) ? React.cloneElement(child, { ...props, ref } as React.Attributes) : null
      )}

      <div>
        {React.Children.map(titleAndSubtitleElements, (child) =>
          React.isValidElement(child) ? React.cloneElement(child, { ...props, ref } as React.Attributes) : null
        )}
      </div>
    </>
  );
});

Component.displayName = 'DROPDOWN_TRIGGER_COMPONENT';

// TODO: fix this `any` type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Trigger: any = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, disabled = false, ...props }, ref) => {
    const buttonClassName = 'px-6 py-3 text-left w-56 select-none flex';

    if (disabled) {
      return (
        <button
          {...props}
          ref={ref}
          className={twMerge(props.asChild ? '' : buttonClassName, 'cursor-not-allowed', props.className)}
          type="button"
          disabled
        >
          <Component className="text-grayscale-48">{children}</Component>
        </button>
      );
    }

    return (
      <Primitive.Trigger
        {...props}
        ref={ref}
        className={twMerge(
          props.asChild ? '' : buttonClassName,
          'hover:bg-blue-10',
          'data-[state=open]:ring-1 data-[state=open]:border-blue-10',
          props.className
        )}
      >
        <Component>{children}</Component>
      </Primitive.Trigger>
    );
  }
);

Trigger.displayName = 'DROPDOWN_TRIGGER';

Trigger.Title = ({ children, ...props }: { children: string; className?: string }) => (
  <Text {...props} as="h1">
    {children}
  </Text>
);

Trigger.Subtitle = ({ children }: { children: string }): JSX.Element => (
  <Text as="h2" className="text-grayscale-48">
    {children}
  </Text>
);

Trigger.Icon = ({ children, className }: { children: JSX.Element; className?: string }): React.ReactNode => {
  const style = {
    marginRight: 8,
    width: 20,
    height: 20,
  };
  return React.Children.map(children, (child) =>
    React.isValidElement(child)
      ? React.cloneElement(child, {
          style,
          className,
        } as React.Attributes)
      : null
  );
};

Trigger.Title.displayName = 'DROPDOWN_TRIGGER_TITLE';
Trigger.Subtitle.displayName = 'DROPDOWN_TRIGGER_SUBTITLE';
