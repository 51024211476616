import React from 'react';
import * as Primitive from '@radix-ui/react-dropdown-menu';
import Text from '../text';
import customTwMerge from '../../utils/twMerge';

type Props = React.PropsWithChildren<Primitive.DropdownMenuProps>;

export const Root = ({ children, ...props }: Props): JSX.Element => (
  <Primitive.Root {...props}>{children}</Primitive.Root>
);

export const Content = ({
  children,
  className = '',
  style,
}: Props & {
  className?: string;
  style?: React.CSSProperties;
}): JSX.Element => (
  <Primitive.Content
    sideOffset={12}
    className={customTwMerge('bg-white shadow-3dp overflow-y-scroll rounded-lg min-w-[220px]', className)}
    style={{
      maxHeight: 400,
      ...(style ?? {}),
    }}
  >
    {children}
  </Primitive.Content>
);

Content.displayName = 'DROPDOWN_CONTENT';

export type ItemProps = Primitive.DropdownMenuItemProps & {
  className?: string;
  onSelect?: (event: Event) => unknown;
  preventPropagation?: boolean;
};

export const Item = ({
  children,
  className,
  onSelect,
  preventPropagation = false,
  ...props
}: ItemProps): JSX.Element => (
  <Primitive.Item
    {...props}
    onSelect={onSelect && !preventPropagation ? onSelect : undefined}
    onClick={
      onSelect && preventPropagation
        ? (event) => {
            event.stopPropagation();
            onSelect(event as unknown as Event);
          }
        : undefined
    }
    className={customTwMerge(
      'px-6 py-3 outline-none hover:bg-grayscale-8 focus:bg-grayscale-8',
      onSelect ? 'cursor-pointer' : '',
      className
    )}
  >
    {children}
  </Primitive.Item>
);

export const Label = ({ children, className, ...props }: { children: string; className?: string }): JSX.Element => (
  <Primitive.Label {...props} className={customTwMerge('px-6 py-1', className)}>
    <Text className="text-grayscale-48">{children}</Text>
  </Primitive.Label>
);

export const Separator = ({ className }: { className?: string }): JSX.Element => (
  <Primitive.Separator>
    <hr className={customTwMerge('border-1 border-grayscale-border py-1 self-center', className)} />
  </Primitive.Separator>
);

export const { Group } = Primitive;
export * from './trigger';
