import React from 'react';
import * as PrimitiveAccordion from '@radix-ui/react-accordion';
import DynamicHeightContainer from '../../ui/layout/container/animated-dynamic-height.containter';
import customTwMerge from '../../utils/twMerge';

type Props = {
  children: React.ReactNode;
  className?: string;
  animate?: boolean;
};

export const Content = ({ children, className, animate, ...props }: Props): JSX.Element => {
  const Wrapper = React.useMemo(() => (animate ? DynamicHeightContainer : React.Fragment), [animate]);

  return (
    <Wrapper>
      <PrimitiveAccordion.Content
        {...props}
        className={customTwMerge('py-6 px-8 flex flex-col', '[&[data-state=closed]]:hidden', className)}
      >
        {children}
      </PrimitiveAccordion.Content>
    </Wrapper>
  );
};
