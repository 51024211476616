import React from 'react';
import BenepassLogo from './components/logo';

type Props = {
  message: string;
};

const OverlayLoader = ({ message }: Props) => (
  <div className="flex items-center justify-center flex-col fixed inset-0 z-50 bg-white/90 [transform:translateZ(0px)]">
    <div className="animate-bounce">
      <BenepassLogo variant="full" size="md" />
    </div>
    <p className="mt-12 text-grayscale-100 text-lg pl-4">{message}</p>
  </div>
);

export default OverlayLoader;
